import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class EnglishRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-12 epost-tile-wrapper" key={post.id}>
              <Link to={post.fields.slug}>
              <article
                className={`epost-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                {post.frontmatter.featuredpost ? (<span className="ribbon"><span className="ribbon-content">NEW!</span></span>) : null}
                <div>
                  <p className="post-meta post-meta-header">
                    <span className="title is-size-4">
                      {post.frontmatter.title}
                    </span>
                  </p>
                  <p>
                    {post.excerpt}
                  </p>
                </div>
              </article>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

EnglishRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query EnglishRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "english-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200, truncate: true)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EnglishRoll data={data} count={count} />}
  />
)
