import React from 'react'
import Layout from '../../../components/Layout'
import EnglishRoll from '../../../components/EnglishRoll'

const AnimationProjectPage = () => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
      style={{
          backgroundImage: `linear-gradient(-90deg, #34e694, #6e8fec)`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          color: 'white',
          padding: '1rem',
        }}
      >
        English study challenge
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
          <div class="tile">
            <h1>About challenge<br/>チャレンジにあたって</h1>
          </div>
          <p>とりあえずは、外国スタッフとのやりとりで、Google翻訳しまくった結果を残す場所。<br />
          そのうち何かプロジェクトとしてまとめ直したい。
          </p>
          <EnglishRoll></EnglishRoll>
        </div>
      </div>
    </section>
  </Layout>
)

export default AnimationProjectPage